import GatsbyImage from "gatsby-image"
import React from "react"

const PodcastCover = ({ fluid = {} }) => {
  return (
    <div className="max-w-2xl">
      <GatsbyImage fluid={fluid} />
    </div>
  )
}

export default PodcastCover
