import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/SEO"

import HeroContainer from "../components/heroContainer"
import audio from "../audio/CC1.mp3"
import PodcastPreview from "../components/podcast/PodcastPreview"
import PageTitle from "../components/PageTitle"
import BlockContentPrimary from "../components/block content/blockContentPrimary"
import { getFixedGatsbyImage } from "gatsby-source-sanity"
import { sanityConfig } from "../sanityConfig"
import BackgroundContainer from "../components/containers/BackgroundContainer"

const PodcastsPage = ({ data }) => {
  const {
    gemPodcast,
    gemPodcastMeta,
    drMikeKirlew,
    drMikeKirlewMeta,
    gemCover,
    kirlewCover,
    title: pageTitle = "Podcasts",
    podCastPageContent,
  } = data

  const fluidBanner =
    getFixedGatsbyImage(
      podCastPageContent?._rawHeaderImage?.asset?._id,
      { maxWidth: 1200 },
      sanityConfig
    ) || data.heroImage.childImageSharp.fluid

  const heroProps = {
    fluid: fluidBanner,
    text: null,
    gradient: true,
  }

  const podcasts = [
    {
      title: drMikeKirlewMeta.title,
      description: drMikeKirlewMeta.description,
      fluid: kirlewCover.childImageSharp.fluid,
      author: "Dr. Mike Kirlew",
      to: "/podcasts/dr-mike-kirlew-presents",
      link: drMikeKirlewMeta.link,
      episodes: drMikeKirlew.edges,
      appleUrl:
        "https://podcasts.apple.com/ca/podcast/dr-mike-kirlew-presents/id922590633",
    },
    {
      title: "Generalist Med Podcast",
      description: gemPodcastMeta.description,
      fluid: gemCover.childImageSharp.fluid,
      author: "Dr. Aaron Rothstein",
      to: "/podcasts/gem",
      link: gemPodcastMeta.link,
      episodes: gemPodcast.edges,
      appleUrl:
        "https://podcasts.apple.com/ca/podcast/the-generalist-medicine-gem-podcast/id1045154033",
    },
  ]

  return (
    //pass custom header to Layout
    <>
      <Layout>
        <SEO title="Podcasts" />

        <HeroContainer props={heroProps} />
        <BackgroundContainer className="grid">
          <PageTitle text={`${pageTitle}`} />
          {podCastPageContent?._rawPodcastPageText && (
            <section className="mx-3 grid mt-10">
              <div className=" justify-self-center max-w-6xl">
                <BlockContentPrimary
                  blockData={podCastPageContent._rawPodcastPageText}
                />
              </div>
            </section>
          )}
          <div className="grid gap-10 my-10 max-w-4xl justify-self-center">
            {podcasts.map((podcast) => (
              <>
                <PodcastPreview {...podcast} />
              </>
            ))}
          </div>
        </BackgroundContainer>

        {/* <iframe
          allow="autoplay *; encrypted-media *; fullscreen *"
          frameborder="0"
          height="450"
          className="w-full max-w-2xl overflow-hidden bg-transparent"
          // style="width:100%;max-width:660px;overflow:hidden;background:transparent;"
          sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
          src="https://embed.podcasts.apple.com/ca/podcast/the-generalist-medicine-gem-podcast/id1045154033"
        ></iframe> */}
      </Layout>
    </>
  )
}

export default PodcastsPage

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "podcasts1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    secondBanner: file(relativePath: { eq: "second_banner.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    gemCover: file(
      relativePath: { eq: "gem-podcast-logo-final-2000x2000.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    kirlewCover: file(relativePath: { eq: "Kirlew1400.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    podCastPageContent: sanityPodcastPage {
      title
      _rawPodcastPageText
      _rawHeaderImage(resolveReferences: { maxDepth: 10 })
    }
    gemPodcast: allFeedGem(limit: 1) {
      edges {
        node {
          id
          title
          link
          pubDate
          isoDate(formatString: "MMM D, YYYY")
          content {
            encoded
          }
          enclosure {
            url
            type
          }
          itunes {
            duration
            subtitle
          }
        }
      }
    }
    gemPodcastMeta: feedGemMeta {
      id
      title
      pubDate
      image {
        url
      }
      docs
      feedUrl
      description
      link
    }
    drMikeKirlew: allFeedDrMikeKirlew(limit: 1) {
      edges {
        node {
          id
          title
          link
          pubDate
          isoDate(formatString: "MMM D, YYYY")
          content {
            encoded
          }
          enclosure {
            url
            type
          }
          itunes {
            duration
            subtitle
          }
        }
      }
    }
    drMikeKirlewMeta: feedDrMikeKirlewMeta {
      id
      title
      pubDate

      image {
        url
      }
      docs
      feedUrl
      description
      link
    }
  }
`
