import React, { useState } from "react"

const EpisodeDetails = ({ details = "Some details" }) => {
  const [visible, setVisible] = useState(false)
  const toggleVisible = () => setVisible(!visible)

  const html = { __html: details }

  return (
    <div className="pl-5 mt-2">
      <button className="  italic" onClick={() => toggleVisible()}>
        {visible ? (
          <span className="text-yellow-500">- Hide details</span>
        ) : (
          <span className=" text-fifth">+ Show Details</span>
        )}
      </button>
      {visible && (
        <div
          className="italic"
          style={{
            overflowWrap: "break-word",
            wordWrap: "break-all",
            wordBreak: "break-word",
          }}
          dangerouslySetInnerHTML={{ __html: details }}
        />
      )}
    </div>
  )
}

export default EpisodeDetails
