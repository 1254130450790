import React, { useState } from "react"
import { AiOutlineArrowRight } from "react-icons/ai"
import ButtonLink from "../ButtonLink"
import Episode from "./Episode"

const PodcastList = ({ episodes = [], to }) => {
  return (
    <>
      <h2>Latest Episode</h2>
      {episodes.map(({ node: episode }, index) => (
        <Episode data={episode} key={`${episode.id}-${index}`} />
      ))}
      <ButtonLink to={to} className="flex items-center justify-self-center">
        See More <AiOutlineArrowRight className=" text-xl ml-4" />
      </ButtonLink>
    </>
  )
}

export default PodcastList
