import { Link } from "gatsby"
import React from "react"
import { FaApple, FaExternalLinkAlt } from "react-icons/fa"
import CardTitle from "../CardTitle"
import PageTitle from "../PageTitle"
import PodcastCover from "./PodcastCover"
import PodcastList from "./PodcastList"

const PodcastPreview = ({
  to,
  link,
  title,
  description,
  fluid,
  author = "author",
  episodes,
  appleUrl,
}) => {
  return (
    <div className="border px-5 md:px-8 py-8 shadow-md rounded-md mx-2">
      <Link
        to={to}
        className="text-center grid justify-items-center p-4"
        title={title}
      >
        <CardTitle
          className=""
          title={{ text: title, className: "md:text-center" }}
          lineClassName="md:justify-self-center"
        />
        {author && <p className="italic text-xl mb-5">{author}</p>}
        <div className="w-full max-w-xs justify-self-center">
          <PodcastCover fluid={fluid} />
        </div>
        <p className="pt-10">{description}</p>
      </Link>
      {/* <div className="grid justify-center">
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="underline hover:text-fifth text-center text-xl"
        >
          Visit podcast website{" "}
          <FaExternalLinkAlt className="inline-block ml-2" />
        </a>
        <a
          href={appleUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="underline hover:text-fifth text-center text-xl my-5 justify-self-center"
          title="Apple Podcasts"
        >
          <FaApple className="text-4xl" />
        </a>
      </div> */}

      <div className="grid gap-y-5 ">
        <PodcastList episodes={episodes} to={to} />
      </div>
    </div>
  )
}

export default PodcastPreview
