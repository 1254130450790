import React from "react"
import ReactAudioPlayer from "react-audio-player"
import { FaExternalLinkAlt } from "react-icons/fa"
import EpisodeDetails from "./EpisodeDetails"

const Episode = ({ data = {} }) => {
  return (
    <div className="border-b pb-5 ">
      <div className="flex flex-col md:flex-row justify-between flex-wrap md:items-center  ">
        <div className="  w-full flex flex-col md:flex-row justify-between">
          <a
            href={data.link}
            target="_blank"
            rel="noopener noreferrer"
            className="underline"
            title="View on podcast website"
          >
            <div className="font-bold">
              {data.title}
              <FaExternalLinkAlt className="inline-block ml-3 text-sm" />
            </div>
          </a>
          <div className="italic whitespace-nowrap">{data.isoDate}</div>
        </div>
        {/* <p className="text-center mt-3 italic">
          Published: {data.pubDate.slice(0, 16)}
        </p>
        <p>{data.itunes.subtitle}</p> */}

        <ReactAudioPlayer
          src={data.enclosure.url}
          controls
          autoPlay={false}
          preload="metadata"
          className="bg-white w-full"
        />
      </div>
      {data.content.encoded && (
        <EpisodeDetails details={data.content.encoded} />
      )}
    </div>
  )
}

export default Episode
